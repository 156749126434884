import React from "react";
import "./About.css";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <h2>About Us</h2>
        <p>
          We are a versatile band based in Christchurch, New Zealand, available
          for weddings, private events, and pubs. Our music spans various genres
          to cater to all audiences.
        </p>
      </div>
    </section>
  );
};

export default About;
